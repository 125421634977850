import axios from "axios";
import router from "@/router";
import ability from "@/libs/acl/ability";
import { initialAbility } from "@/libs/acl/config";

const API_MYCLINIC_FORM = process.env.VUE_APP_RUTA_API || "";

// Crear instancias de axios
export const userAxios = axios.create({
    baseURL: `${API_MYCLINIC_FORM}/api`,
});
export const apiV2 = axios.create({
    baseURL: `${API_MYCLINIC_FORM}/api/v2`,
});
export const jotAxios = axios.create({
    baseURL: `https://api.jotform.com/`,
});
export const imageAxios = axios.create({
    baseURL: `${API_MYCLINIC_FORM}/api/upload-file`,
});

// Configurar imageAxios para manejar archivos
imageAxios.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'multipart/form-data';
    return config;
});

// Arreglo de instancias axios para configuración común
const arrAxios = {
    userAxios,
    apiV2,
};

// Iterar sobre las instancias en arrAxios para añadir interceptores
Object.values(arrAxios).forEach((axiosInstance) => {
    axiosInstance.interceptors.request.use((config) => {
        const token = localStorage.getItem('accessToken') || null;
        const facility = localStorage.getItem('facility') || null;

        config.headers.Facility = facility;
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Content-Type'] = 'application/json;charset=UTF-8';

        return config;
    });

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("userData");
                
                // Resetear habilidades
                ability.update(initialAbility);

                // Limpiar datos de la instalación
                localStorage.removeItem('facility');
                localStorage.removeItem('setFacilityOptions');

                // Redirigir a la página de login
                router.push({ name: "auth-login" }).catch(() => {});
            }
            return Promise.reject(error);
        }
    );
});
